import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,

  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/Psoriazal-plus-mobile.jpg"
import LuteinComplexTablet from "./../../images/Psoriazal-plus-tablet.jpg"
import LuteinComplexDesktop from "./../../images/Psoriazal-plus-desktop.jpg"
import LuteinComplexXL from "./../../images/Psoriazal-plus-desktop-xl.jpg"
import Feature1 from "./../../images/psoriazal-plus-skin.jpg"
import Feature2 from "./../../images/psoriazal-plus-sarsaparila.jpg"
import Feature3 from "./../../images/psoriazal-plus-herbs.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"


const PsoriazalPlusPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "PsoriazalPlusEn" }}>
    <Seo title="Psoriazal Plus - Skin support in psoriasis" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>PSORIAZAL PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Psoriazal Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Psoriazal Plus
          </h5>
          <h1 className="display-4 font-light">Skin support in psoriasis</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Psoriazal Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Skin support in psoriasis
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Psoriasis Plus contains natural herbs with proven beneficial
            properties on the skin and the fight against psoriasis.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/psoriazal-plus-envh1007"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Skin care from within</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Skin care from within"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Psoriazal Plus supports the health and the function of the skin.
            Plant extracts support the blood supply to the skin and support its
            restorative functions. The active substances have a complex effect
            on the skin, helping to tone it, to stabilize the activity of its
            blood vessels, to improve metabolism and the processes of
            epithelialization and regeneration.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Supports blood circulation</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Supports blood circulation"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            The effectiveness of the main ingredient of Psoriazal Plus -
            sarsaparilla extract is due to its large number of bioactive
            substances such as saponins, steroids and minerals that support the
            body's cleansing functions, stimulating the release of harmful
            substances. Sarsaparilla also has a strong anti-allergic, diuretic
            and hormone-activating effect. Therefore, in addition to psoriasis,
            it is also used for eczema, urticaria and hair loss. It is also used
            as an immunostimulant with a general tonic effect.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Selected herbs from nature</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Selected herbs from nature"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Psoriazal Plus contains herbal extracts of Sarsaparilla root, birch
            leaves, nettle, burdock root and whip stalks. A combination of
            beneficial active ingredients with a positive effect on the skin and
            in psoriasis.
          </p>
          <p>
            Birch leaf extract has a strong dermatotonic, draining and
            antimicrobial action. Burdock extract is rich in active ingredients
            that accelerate the growth of new cells in the body, including
            healthy skin cells. Nettle extract includes tannins and proteins
            with dermatotonic, antiallergic, soothing, antipruritic and
            antisclerotic effects. In turn, whip extract also has
            anti-inflammatory and antimicrobial properties.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality. The intake of an
            extract with a proven effect guarantees an optimal effect on the
            body's functions. The plant extracts included in Psoriazal Plus
            complement each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Sarsaparilla</strong> – roots (Extr. Rad. Smilax
                    officinalis) 100 mg
                  </p>
                  <p>
                    <strong>Birch</strong> – leaves (Extr. Fol. Betulae albae)
                    75 mg
                  </p>
                  <p>
                    <strong>Nettle</strong> – leaves (Extr. Fol. Urticae
                    dioicae) 25 mg
                  </p>
                  <p>
                    <strong>Burdock</strong> – root (Extr. Rad. Bardanae) 25 mg
                  </p>
                  <p>
                    <strong>Whip</strong> – stalks (Extr. Hrb. Agrimoniae) 25 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Take 1 tablet Psoriazal Plus two times daily after meals, no
                    less than 3 months, without interruption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Psoriazal Plus is a dietary supplement and
                    does not cause irritation and hypersensitivity, and can be
                    taken for a long period. Psoriazal Plus can be taken alone
                    or in combination with medication prescribed by your doctor.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    The effectiveness of sarsaparilla extract is due to the
                    large number of bioactive substances it contains, such as
                    saponins, steroids and minerals, which support the body's
                    cleansing functions by stimulating the release of harmful
                    substances from it. Sarsaparilla also has a strong
                    anti-allergic, diuretic and hormone-activating effect.
                    Therefore, in addition to psoriasis, it is also used for
                    eczema, urticaria and hair loss. It is also used as an
                    immunostimulant with a general tonic effect.
                  </p>
                  <p>
                    Birch leaf extract contains substances that are due to its
                    strong dermatotonic, draining and antimicrobial action.
                  </p>
                  <p>
                    Burdock extract is rich in active ingredients that
                    accelerate the growth of new cells in the body, including
                    healthy skin cells. This action is mainly due to the inulin
                    they contain. In addition, they have anti-allergic,
                    antiseptic and epithelial effects.
                  </p>
                  <p>
                    Nettle extract includes tannins and proteins, which is why
                    it has a dermatotonic, anti-allergic, soothing, antipruritic
                    and antisclerotic effect.
                  </p>
                  <p>
                    In turn, whip extract also has anti-inflammatory and
                    antimicrobial properties.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PsoriazalPlusPageEn
